<template>
  <div id="article">
    <div class="article_warp">
      <div class="article_head_warp">
        <div class="article_title">
          {{ articleData.arTitle }}
        </div>
        <div class="article_other">
          <div class="article_other_item">
            类别： {{ articleData.arCatalog }}
          </div>
          <div class="article_other_item">
            {{ articleData.releaseTime }}
          </div>
        </div>
      </div>
      <div class="article_head_main" v-show="articleData.arContent" v-html="articleData.arContent">
      </div>
      <div class="article_head_bottom" v-show="articleData.downloadList && 0 !== articleData.downloadList.length">
        <div class="bottom_title">
          附件下载
        </div>
        <div class="bottom_main">
          <div class="bottom_main_item" v-for="item in articleData.downloadList" :key="item.fileId">
            <el-link :href="download + item.fileId" :underline="false">{{ item.fileName }}</el-link>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="gotop" v-show="gotop" @click="toTop">
          <el-image style="height: 50px;width: 50px" class="copyright_img" src="./statics/icon/pages/icon/top.png"
                    fit="fill"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'articleDetails',
  watch: {
    $route: {
      handler(val) {
        this.id = val.query.id
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      gotop: false,
      //文章主键
      id: "",
      //文章数据
      articleData: {},
    }
  },
  created: function () {
    this.getData();
    this.setViews();
    // 此处true需要加上，不加滚动事件可能绑定不成功
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    //浏览次数增加
    setViews() {
      this.request.get('/article/setViews?key=' + this.config.key + "&id=" + this.id).then(res => {
        console.log(res);
      });
    },
    handleScroll() {
      let scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
    },
    toTop() {

      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    //获取文章数据
    getData() {
      this.request.get('/article/getInfo?key=' + this.config.key + "&id=" + this.id).then(res => {
        this.articleData = (undefined === res.data) ? {} : res.data;
        this.articleData.arContent = this.articleData.arContent ? this.articleData.arContent.replaceAll('src="http://www.0769aj.com:8580', 'src="http://www.0769aj.com') : "";
      });
    }
  }
}
</script>
<style>
@import "../../assets/styles/pages/article.css";
</style>
